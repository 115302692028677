<template>
	<main>
		<section>
            <header>
                <h5 class="title">{{ title }}</h5>
                <div class="out" @click="$emit('close')">X</div>
            </header>
            <article>
                <div v-if="item && loaded" class="items">
                    <figure>
                        <img v-if="item.avatar" :src="imgURL + item.avatar" alt="">
                        <span v-else>{{item.name.charAt(0)}}</span>
                    </figure>
                    <span class="name">{{item.name}}</span>
                    <div class="item">
                        <label>Organization</label>
                        <span>{{org.name}}</span>
                    </div>
                    <div class="item" v-if="item.user_organizations_role.user_client_roles?.length">
                        <label>Clients</label>
                        <template v-for="(client, i) in item.user_organizations_role.user_client_roles" :key="i">
                            <span>{{client.client.name}}</span>
                        </template>
                    </div>
                    <div class="item">
                        <label>Last Login</label>
                        <span>{{item.last_login.replace(' ', ' at ')}}</span>
                    </div>
                </div>
                <div v-else class="loading">
                    <div class="loader"></div>
                </div>
            </article>
		</section>
	</main>
</template>

<script>
import axiosInstance from '@/services/AxiosTokenInstance';
export default {
    props: {
        title: {
            required: true,
            type: String
        },
        data: {
            required: true,
            type: Number
        }
    },

    data() {
        return {
            item: undefined,
            imgURL: process.env.VUE_APP_DO_SPACES,
            org: localStorage.organization ? JSON.parse(localStorage.organization) : {},
            loaded: false
        };
    },

    emits: ['close'],

    mounted() {
        this.fetchUser()
    },

    methods: {
        async fetchUser() {
            const response = await axiosInstance.get(`get-org-ser-details?org_id=${this.org.id}&user_id=${this.data}`).finally(() => this.loaded = true)
            this.item = response.data.data
        }
    },
};
</script>

<style lang="scss" scoped>
main {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(100,100,100,0.4);
	z-index: 5;
	display: flex;
	justify-content: center;
	align-items: center;

	section {
		background: white;
		width: 95%;
		max-width: 400px;
		height: fit-content;
		border-radius: 2px;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		position: relative;

		header, article {
			padding: 20px;
		}

		header {
			height: 10%;
			box-shadow: 0px 1px 6px #e0e0e0;
			border-bottom: 1px solid #eeeeee;
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: sticky;
			top: 0;

			h5, div {
				font-weight: 600;
				font-size: 1.3rem;
			}

			.out {
				user-select: none;
				cursor: pointer;
				transition: 0.2s;

				&:hover {
					color: grey;
				}
			}
		}

		article {
			display: flex;
			flex-direction: column;
			align-items: center;
			overflow: auto;

			.items {
				display: flex;
                flex-direction: column;
                align-items: center;
                gap: 1rem;

                figure {
                    width: 150px;
                    height: 150px;
                    border-radius: 50%;
                    overflow: hidden;
                    border: solid 2px var(--primary-color);

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    span {
                        width: 100%;
                        height: 100%;
                        background: var(--primary-color);
                        color: white;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-weight: 500;
                        font-size: 2rem;
                    }
                }

                .name {
                    font-weight: 600;
                    font-size: 2rem;
                }

                .item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    label {
                        font-weight: 500;
                        color: var(--primary-color);
                    }
                }
			}
		}
	}
}
</style>