<template>
    <div class="user-concern-area" @keydown.enter="ignoreEnter" @keyup="handleInput"
        :class="{ replying: replyingToMe, reply: chat.replyTo, flash, isEditing, isAttach: chat.type === 'attachment' && chat.media && chat.media.length, me: isMine, call: chat.type === 'joinCall' || chat.type === 'endCall' || chat.type === 'rejectCall' }"
        @mouseenter="seeOptions = true" @mouseleave="seeOptions = false">
        <div class="reply" :class="{ replying: replyingToMe }" v-if="chat.replyTo">
            <span class="arrow" />
            <span>{{ chat.replyTo.text }}</span>
        </div>
        <div class="attachment" v-if="chat.type === 'attachment' && chat.media && chat.media.length">
            <template v-for="(att, index) in chat.media" :key="index">
                <div v-if="index <= 3"
                    :class="{ image: att.extension === 'png' || att.extension === 'jpg' || att.extension === 'jpeg' || att.extension === 'webp' || att.extension === 'gif', doc: att.extension !== 'png' && att.extension !== 'webp' && att.extension !== 'gif' && att.extension !== 'jpg' && att.extension !== 'jpeg' && att.extension !== 'text' && att.extension !== 'joinCall', group: (att.extension === 'png' || att.extension === 'jpg' || att.extension === 'jpeg' || att.extension === 'gif') && chat.media.length > 1 }">
                    <video v-if="att.extension === 'mp4' || att.extension == 'MOV' || att.extension == 'avi'"
                        :src="trimSrc(att.url)" :autoplay="false" controls="" loop="" muted="" frameborder="0">
                        Your browser does not support HTML videos.
                    </video>
                    <span
                        v-else-if="att.mimetype !== 'image/png' && att.mimetype !== 'image/jpeg' && att.mimetype !== 'image/jpg' && att.mimetype !== 'image/gif' && att.mimetype !== 'image/webp' && att.extension !== 'png' && att.extension !== 'gif' && att.extension !== 'jpg' && att.extension !== 'jpeg' && att.extension !== 'webp' && att.extension !== 'text' && att.extension !== 'joinCall'"
                        class="document" @click="openDoc(att.url)">
                        <img :src="`/assets/images/icons/${att.extension}.png`" alt="doc" /><span>{{ att.name }}</span>
                    </span>
                    <template v-else>
                        <img v-if="chat.media.length <= 4" :src="trimSrc(att.url)" alt="picture"
                            @click="handleGallery(chat.media, index)" />
                        <template v-else>
                            <img v-if="index < 3" :src="trimSrc(att.url)" alt="picture"
                                @click="handleGallery(chat.media, index)" />
                            <span v-else-if="index === 3" @click="handleGallery(chat.media, index)">+</span>
                        </template>
                    </template>
                </div>
            </template>
        </div>
        <div class="image-text" ref="txt" v-if="chat.type === 'attachment' && chat.text">{{ chat.text }}</div>
        <div class="text" v-else-if="chat.type === 'text' && chat.text">
            <template v-if="!isEditing">
                <span :id="`msg-${chat._id}`" ref="txt">{{ message }}</span>
                <figure v-show="seeOptions && !showOptions" @click="; (seeOptions = false), (showOptions = true)">
                    <img src="/assets/images/black_dots.svg" alt="edit">
                </figure>
                <ChatOptions v-if="showOptions" :is-mine="isMine" v-click-outside="handleOutsideOptions"
                    @handleAction="handleAction" />
            </template>
            <template v-else>
                <div class="info">
                    <span>Editing Message.</span>
                    <span>Press <strong>Enter</strong> to <small @click="updateMessage">send</small> and
                        <strong>Escape</strong> to <small @click="cancelEdit">cancel.</small></span>
                </div>
                <textarea type="text" v-model="message" />
                <figure @click="updateMessage">
                    <img class="confirm" src="/assets/images/confirm.svg" alt="edit">
                </figure>
            </template>
        </div>
        <template v-else-if="chat.type === 'joinCall'">
            <div class="joinbox">
                <div class="start-call">
                    <div class="icon">
                        <img src="/assets/images/call.svg" alt="call icon">
                    </div>
                    <span>{{ chat.sentBy.name }} started a call</span>
                </div>
            </div>
        </template>
        <template v-else-if="chat.type === 'endCall' || chat.type === 'rejectCall'">
            <div class="endbox">
                <div class="icon">
                    <img src="/assets/images/call_ended.svg" alt="call icon">
                </div>
                <span>{{ chat.text }}</span>
            </div>
        </template>
        <gallery v-if="Object.keys(toGallery).length" :data="toGallery" @close="toGallery = {}" />
    </div>
    <UserDetails v-if="userToSee" :title="`User Card`" :data="userToSee" @close="userToSee = 0" />
</template>

<script>
import Gallery from '@/components/Ui/Chat/Gallery.vue'
import UserDetails from "./UserDetails.vue";
import nodeInstance from '@/services/NodeServiceinstance';
import ChatOptions from './ChatOptions'
export default {
    props: {
        chat: {
            required: false,
            type: Object,
        },
        isMine: {
            required: true,
            type: Boolean,
            default: () => false
        },
        replyingToMe: {
            type: Boolean,
            default: () => false
        }
    },

    data() {
        return {
            toGallery: {},
            userToSee: 0,
            flash: false,
            user: localStorage['U_P'] ? JSON.parse(localStorage['U_P']) : {},
            seeOptions: false,
            showOptions: false,
            isEditing: false,
            messageCopy: this.chat.text,
            message: this.chat.text
        }
    },

    watch: {
        chat: {
            deep: true,
            handler(val) {
                this.message = val.text
                this.messageCopy = val.text
                if(val.type === 'text' && val.mentionedUsers?.length) this.handleMentions()
            }
        },

    },

    emits: ['join', 'reply', 'remove', 'edited'],

    components: {
        Gallery,
        UserDetails,
        ChatOptions
    },

    mounted() {
        if(this.chat.type === 'text' && this.chat.mentionedUsers?.length) this.handleMentions()
    },

    methods: {
        trimSrc(src) {
            const trimmedSrc = src.replace(/https?:../,'')
            return `https://${trimmedSrc}`
        },

        handleMentions() {
            let msg = this.message
            const me = this.chat.receivers.filter(el => el.userId === this.user.id)

            this.chat.mentionedUsers.forEach(el => {
                msg = msg.replace(`@${el.name}`, `<mark data-id="${el.id}">@${el.name}</mark>`)
                if (me.length && !me[0].isRead && (this.user.id === el.id || el.id === 0)) this.flash = true
            })
            
            setTimeout(() => {
                this.$refs.txt.innerHTML = msg

                const tags = this.$refs.txt.querySelectorAll('mark')
                this.handleTags(tags)
            }, 100);
            
        },

        handleGallery(items, sel) {
            this.toGallery = {
                items,
                init: sel
            }
        },

        handleTags(data) {
            data.forEach(el => {
                if (el.innerText !== '@everyone') el.addEventListener('click', this.handleTagClick)
            })
        },

        handleTagClick(e) {
            this.userToSee = e.target.attributes['data-id'].value
        },

        openDoc(doc) {
            window.open(doc)
        },

        async updateMessage() {
            const response = await nodeInstance.patch(`chats/chat/${this.chat._id}`, {
                message: this.message
            })

            this.isEditing = false
            this.seeOptions = false
            this.messageCopy = this.message
            this.$emit('edited', response.data.data.updatedAt)
            this.handleMentions()
        },

        ignoreEnter(e) {
            if (!e.shiftKey) e.preventDefault()
        },

        handleInput(e) {
            if (!this.isEditing) return

            if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault()
                this.updateMessage()
                return
            }

            if (e.key === 'Escape') {
                this.cancelEdit()
                return
            }
        },

        cancelEdit() {
            this.isEditing = false
            this.message = this.messageCopy
        },

        handleAction(data) {
            this.showOptions = false
            switch (data) {
                case 'edit':
                    this.isEditing = true
                    break;
                case 'delete':
                    this.deleteChat()
                    break;
                case 'reply':
                    this.$emit('reply')
                    break;
            }
        },

        async deleteChat() {
            await nodeInstance.delete(`chats/chat/${this.chat._id}`)

            this.$emit('remove')
        },

        handleOutsideOptions() {
            this.showOptions = false
        }
    },
}
</script>

<style lang="scss" scoped>
.user-concern-area {
    background: #F2F5F8;
    padding: 10px;
    max-width: 90%;
    border-radius: 4px;
    position: relative;

    &.replying {
        background: #f2f5f873;
    }

    &.reply {
        margin-top: 34px;
        border-radius: 0px 0px 4px 4px;
    }

    &.me {
        .reply {
            left: unset;
            right: 0;

            .arrow {
                left: unset;
                right: -30px;
                border-color: $grey $grey transparent transparent;
                border-radius: 0 4px 0 0;
            }
        }
    }

    .reply {
        background: #e6e8eb;
        color: rgba(0, 0, 0, 0.5);
        position: absolute;
        width: max-content;
        max-width: 60vw;
        left: 0;
        top: -35px;
        padding: 10px;
        border-radius: 4px 4px 0 0;
        font-size: 12px;

        &.replying {
            background: #e6e8eb5d;
        }

        span {
            width: 100%;
            white-space: nowrap;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .arrow {
            position: absolute;
            top: 16px;
            left: -30px;
            width: 28px;
            height: 16px;
            border: solid 1px;
            border-color: $grey transparent transparent $grey;
            border-radius: 4px 0 0 0;
        }
    }

    &.flash {
        animation: flash 2s ease-in-out;
    }

    span {
        white-space: pre-line;
    }

    &.call {
        background: none;

        &.me {
            background: none;

            .joinbox {
                .start-call {
                    flex-direction: row-reverse;
                }

                .icon {
                    transform: scaleX(-1);
                }

                .join-call {
                    align-items: flex-end;
                }
            }

            .endbox {
                flex-direction: row-reverse;

                .icon {
                    transform: scaleX(-1);
                }
            }
        }

        .endbox {
            display: flex;
            align-items: center;
            gap: 10px;

            .icon {
                width: 15px;

                img {
                    width: 100%;
                }
            }

            hr {
                border: none;
                width: 250px;
                height: 2px;
                background: #F2F5F8;
            }

            span {
                display: flex;
                justify-content: center;
                color: #cad6e2;
            }
        }

        .joinbox {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .start-call {
                display: flex;
                align-items: center;
                gap: 10px;
            }

            .join-call {
                width: 250px;
                background: var(--primary-color);
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                overflow: hidden;

                span {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    color: white;
                    padding: 5px 10px;
                    font-size: 0.8rem;

                    .here {
                        cursor: pointer;
                        padding: 5px;
                        background: white;
                        color: var(--primary-color);
                        border-radius: 2px;
                        transition: 0.2s;

                        &:hover {
                            background: $grey;
                        }
                    }
                }
            }

            .icon {
                width: 15px;

                img {
                    width: 100%;
                }
            }

            hr {
                border: none;
                width: 250px;
                height: 2px;
                background: #F2F5F8;
            }

            span {
                display: flex;
                justify-content: center;
                color: #cad6e2;
            }
        }
    }

    &.isAttach {
        padding: 0;
        background: unset;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    .attachment {
        display: flex;
        flex-wrap: wrap;
        max-width: 500px;

        .document {
            cursor: pointer;
            user-select: none;
        }

        .image {
            height: 150px;
            width: fit-content;
            max-width: 250px;
            padding: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            border: solid 1px #F2F5F8;
            cursor: pointer;
            transition: 0.2s;

            &:hover {
                background: rgba(0, 0, 0, 0.1);
            }

            &.group {
                flex: 1 0 50%;
                width: 62.5px;
                height: 75px;

                span {
                    color: var(--primary-color);
                    font-size: 2rem;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                img {
                    height: 90%;
                }
            }

            img {
                height: 100%;
                object-fit: contain;
            }
        }

        .doc {
            padding: 10px;
            background: #F2F5F8;
            max-width: 100%;

            a {
                text-decoration: none;
            }

            video {
                width: 100%;
            }
        }
    }

    .image-text {
        background: #F2F5F8;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 10px;
    }

    .text {
        position: relative;
        display: flex;
        align-items: center;

        .info {
            position: absolute;
            top: -30px;
            left: -4px;
            background: var(--primary-color);
            padding: 8px 10px 4px;
            border-radius: 4px 4px 0px 0px;
            display: flex;
            gap: 4px;
            font-size: 12px;

            small {
                font-size: 12px;
                text-decoration: underline;
                font-weight: 500;
                cursor: pointer;
            }
        }

        figure {
            cursor: pointer;
            position: absolute;
            width: 60px;
            height: 40px;
            right: -60px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 25%;
                height: 100%;
                opacity: 0.3;

                &.confirm {
                    height: 40%;
                }
            }
        }

        textarea {
            background: rgba(58, 58, 58, 0.6);
            padding: 10px;
            color: white;
            width: 100%;
            border-radius: 4px;
            resize: none;

            &:focus {
                outline: none;
            }
        }
    }

    &.me {
        background: var(--primary-color);
        color: white;

        &.isEditing {
            width: 100%;
            padding: 4px;
            border-radius: 0px 4px 4px 4px;
        }

        &.isAttach {
            background: unset;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }

        .image-text {
            background: var(--primary-color);
        }

        .text {
            figure {
                right: unset;
                left: -60px;
            }
        }

        .document {
            color: black;
        }
    }
}

@keyframes flash {
    0% {
        max-width: 100%;
        width: 100%;
        background: #e28b187e;
    }

    80% {
        width: 100%;
    }

    100% {
        width: fit-content;
    }
}</style>