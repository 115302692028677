<template>
    <div class="tag">
        <div class="search">
            <span>
                Searching for:
            </span>
            <span class="key">@{{ searchKey }}</span>
        </div>
        <hr>
        <div class="users" ref="users">
            <template v-for="(user, i) in tagAvailable" :key="i">
                <div class="user" :class="{ select: selected === i }" @mouseenter="selected = i" @click="sendUser">
                    <figure>
                        <img v-if="user.userInfo.avatar" :src="imgURL + user.userInfo.avatar" alt="">
                        <span v-else class="no-img">{{ user.userInfo.name.charAt(0) }}</span>
                    </figure>
                    <span>{{ user.userInfo.name }}</span>
                </div>
            </template>
            <div class="user everyone" :class="{ select: selected === 'all' }" @mouseenter="selected = 'all'"
                @click="sendUser" v-if="containsEveryone">
                <span>everyone</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        searchKey: {
            type: String,
            default: () => ''
        },
        users: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            user: localStorage['U_P'] ? JSON.parse(localStorage['U_P']) : {},
            selected: 0,
            imgURL: process.env.VUE_APP_DO_SPACES
        }
    },

    watch: {
        searchKey() {
            if (this.containsEveryone && !this.tagAvailable.length) this.selected = 'all'
        }
    },

    computed: {
        tagAvailable() {
            return this.users.filter(el => el.userId !== this.user.id).filter(el => el.userInfo.name.toLowerCase().includes(this.searchKey.toLowerCase()))
        },

        containsEveryone() {
            return 'everyone'.includes(this.searchKey)
        },
    },

    mounted() {
        window.addEventListener('keydown', this.handleKeys)
    },

    beforeUnmount() {
        window.removeEventListener('keydown', this.handleKeys)
    },

    methods: {
        handleKeys(e) {
            if (e.key === 'ArrowDown') {
                if (this.selected < this.tagAvailable.length - 1) {
                    this.selected += 1
                }
                else if (this.selected === (this.tagAvailable.length - 1)) {
                    this.selected = 'all'
                }

                if (this.selected >= 5 || this.selected === 'all') {
                    this.$refs.users.scrollTop = this.$refs.users.scrollTop + 60
                }
                return
            }

            if (e.key === 'ArrowUp') {
                if (this.selected > 0) {
                    this.selected -= 1
                }
                else if (this.selected === 'all') {
                    this.selected = this.tagAvailable.length - 1
                }
                if (this.selected <= this.tagAvailable.length - 4) {
                    this.$refs.users.scrollTop = this.$refs.users.scrollTop - 60
                }
                return
            }

            if(e.key === 'Enter') {
                this.sendUser()
            }
        },

        sendUser() {
            if (this.selected === 'all') {
                this.$emit('tagUser', {
                    name: 'everyone',
                    id: 0
                })
                return
            }

            this.$emit('tagUser', {
                name: this.tagAvailable[this.selected].userInfo.name,
                id: this.tagAvailable[this.selected].userId
            })
        }
    }
}
</script>

<style lang="scss">
.tag {
    position: absolute;
    background: #f2f5f8;
    padding: 20px;
    width: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    bottom: 75px;
    left: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    gap: .5rem;

    .search {
        .key {
            color: var(--primary-color);
        }
    }

    hr {
        border: none;
        height: 1px;
        width: 100%;
        background: white;
    }

    .users {
        display: flex;
        flex-direction: column;
        max-height: 300px;
        overflow: auto;

        .user {
            display: flex;
            align-items: center;
            gap: 1rem;
            padding: 10px;
            background: #cacccf52;

            &.select {
                background: #a2a4a752;
            }

            &.everyone {
                background: #cbe0ff52;

                &.select {
                    background: #bcd1f052;
                }

                span {
                    color: var(--primary-color);
                    font-weight: 400;
                }
            }

            figure {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                span {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    font-weight: 500;
                    background: var(--primary-color);
                }
            }
        }
    }
}
</style>