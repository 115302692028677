<template>
    <article :class="{other: !isMine}">
        <template v-for="(item, i) in options" :key="i">
            <figure v-if="!item.isMine || isMine === item.isMine" @click="$emit('handleAction', item.tag)">
                <img :class="[item.class]" :src="item.icon" :alt="item.tag" />
            </figure>   
        </template>
    </article>
</template>

<script>
export default {
    props: {
        isMine: {
            type: Boolean,
            default: () => false
        }
    },

    computed: {
        options() {
            return [
                {
                    tag: "edit",
                    icon: "/assets/images/pencil.svg",
                    isMine: true,
                },
                {
                    tag: "reply",
                    icon: "/assets/images/reply.svg",
                    isMine: false,
                },
                {
                    tag: "delete",
                    icon: "/assets/images/delete.svg",
                    class: "delete",
                    isMine: true,
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
    article {
        position: absolute;
        right: calc(100% + 20px);
        color: black;
        display: flex;
        gap: 8px;

        &.other {
            right: unset;
            left: calc(100% + 20px);
        }

        figure {
            width: 24px;
            height: 24px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            transition: 0.2s;

            &:hover {
                background: rgba($grey, 0.5);
            }

            img {
                width: 70%;
                opacity: 0.5;

                &.delete {
                    opacity: 0.8;
                    width: 50%;
                }
            }
        }
    }
</style>