<template>
    <div class="group-popup">
        <div class="popup">
            <div class="title">Create Group</div>
            <div class="info">
                <div class="item">
                    <label for="">Group Image</label>
                    <div class="import">
                        <label :class="{wImage: image.length}">
                            <input ref="importImage" type="file" accept="image/png, image/jpeg" @change="importImages">
                            <span v-if="image.length" class="img">
                                <img :src="'data:image/jpeg;base64,'+image" alt="">
                            </span>
                            <span v-else>+</span>

                        </label>
                    </div>
                </div>
                <div class="item">
                    <label for="">Group Name</label>
                    <input type="text" placeholder="Insert Group Name" v-model="groupName">
                </div>
                <template v-if="users">
                    <div class="item">
                        <label for="">Users to Add</label>
                        <input type="text" placeholder="Search for Users" v-model="userSearch" @keyup="searchChatUsers">
                    </div>
                    <transition name="slide-down">
                        <div v-if="results.length" class="results">
                            <div class="user" v-for="(user,index) in results" :key="index" @click="handleSelected(user)">
                                <div v-if="user.avatar" class="img" >
                                    <img :src="imgURL + user.avatar" alt="">
                                </div>
                                <div v-else class="no-img" >
                                    <span>{{user.name.charAt(0)}}</span>
                                </div>
                                <div class="name">{{user.name}}</div>
                            </div>
                        </div>
                    </transition>
                    <div class="selected" v-if="selected.length">
                        <div class="user" v-for="(user,index) in selected" :key="index">
                            <template v-if="index <= 3">
                                <div v-if="user.avatar" class="img" :title="user.name" @click="removeUser(index)">
                                    <img :src="imgURL + user.avatar" alt="">
                                </div>
                                <div v-else class="no-img" :title="user.name" @click="removeUser(index)">
                                    <span>{{user.name.charAt(0)}}</span>
                                </div>
                            </template>
                            <template v-if="index === 4">
                                <div class="more" >
                                    +
                                </div>
                            </template>

                        </div>
                    </div>
                </template>
                <div class="buttons">
                    <span @click="handleSend">confirm</span>
                    <span @click="$emit('close')">cancel</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import {
    SEARCH_CHAT_USERS_ACTION,
} from '@/store/storeconstants';
export default {
    props: {
        users: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            image: '',
            groupName: '',
            userSearch: '',
            results: [],
            organization_details: JSON.parse(localStorage.getItem('organization')),
            selected: []
        }
    },

    methods: {
        ...mapActions('chat', {
            fetchChatUsers: SEARCH_CHAT_USERS_ACTION,
        }),

        importImages(event) {
            const file = event.target.files[0]

			if (FileReader && file) {
				const fr = new FileReader();
				fr.readAsDataURL(file);
				fr.onload = () => {
                    let encodedImg = fr.result.split(",");
					this.image = encodedImg[1]

                    this.$refs.importImage.value = ''
				}
			}
        },

        handleSend() {
            let toSend = {
                image: this.image,
                name: this.groupName
            }

            if(this.selected.length) toSend.users = this.selected
            this.$emit('data', toSend)
        },

        async searchChatUsers(){
            if(this.userSearch.length > 0){
                let params = {
                    org_id: this.organization_details.id,
                    search_data: this.userSearch,
                };
                await this.fetchChatUsers(params)
                .then((data) => {
                    if(data.length){
                        this.results = data.filter(el => {
                            return !this.selected.find(bel => {
                                return el.userId === bel.userId
                            })
                        });
                    }else{
                        this.results = [];
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }else{
                this.results = [];
            }
        },

        handleSelected(user) {
            this.selected.push(user)
            this.searchChatUsers()
        },

        removeUser(index) {
            this.selected.splice(index,1)
            this.searchChatUsers()
        },
    }
}
</script>

<style lang="scss" scoped>
.slide-down-enter-active {
  animation: slide-down .5s;
}
.slide-down-leave-active {
  animation: slide-down .5s reverse;
}
    .group-popup {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.2);
        backdrop-filter: blur(2px);
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        .popup {
            border-radius: 5px;
            background: white;
            width: 400px;
            box-shadow: 0px 1px 6px #CAD6E280;
            border-bottom: solid 1px #F2F5F8;
            .title {
                box-shadow: 0px 1px 6px #CAD6E280;
                border-bottom: solid 1px #F2F5F8;
                padding: 20px;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 1.2rem;
            }
            .info {
                width: 100%;
                padding: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;
                .item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    label {
                        font-weight: 500;
                    }
                    input {
                        width: 70%;
                        border: solid 1px #CAD6E280;
                        text-align: center;
                        padding: 10px;
                        border-radius: 5px;
                    }
                    .import {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        label {
                            width: 100px;
                            height: 100px;
                            margin: 0;
                            cursor: pointer;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border: solid 1px #0072FF56;
                            border-style: dashed;
                            border-radius: 5px;
                            transition: 0.2s;
                            font-size: 3rem;
                            color: #0072FF;

                            &.wImage {
                                border-radius: 50%;
                                overflow: hidden;
                                border: none;
                            }

                            &:hover {
                                background: #0072FF;
                                color: white;
                                border: solid 1px transparent;
                            }

                            .img {
                                width: 100%;
                                height: 100%;
                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }

                            input {
                                display: none;
                            }
                        }
                    }
                }

                .results {
                    margin-top: -20px;
                    width: 70%;
                    height: 150px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    overflow: auto;
                    background: #f2f5f871;
                    &.empty {
                        justify-content: center;
                    }
                    .nothing {
                        color: #CAD6E2;
                    }
                    .user {
                        width: 100%;
                        display: flex;
                        gap: 5px;
                        align-items: center;
                        border-bottom: solid 1px #CAD6E280;
                        user-select: none;
                        cursor: pointer;
                        padding: 10px;
                        transition: 0.2s;
                        &:last-of-type {
                            border-bottom: none;
                        }
                        &:hover {
                            background: var(--primary-color);
                            color: white;

                            .no-img {
                                background: white;
                                color: var(--primary-color);
                            }
                        }
                        .img {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            overflow: hidden;
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                        .no-img {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            overflow: hidden;
                            background: var(--primary-color);
                            color: white;
                            font-weight: 500;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            transition: 0.2s;
                            text-transform: uppercase;
                        }
                    }
                }

                .selected {
                    width: 70%;
                    display: flex;
                    justify-content: center;
                    overflow: hidden;
                    .user{
                        margin-left: -15px;
                        user-select: none;
                        cursor: pointer;
                        &:first-of-type {
                            margin-left: 0;
                        }
                        .img {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            overflow: hidden;
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                        .no-img {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            overflow: hidden;
                            background: var(--primary-color);
                            color: white;
                            font-weight: 500;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            transition: 0.2s;
                            text-transform: uppercase;
                        }
                        .more {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            overflow: hidden;
                            background: #CAD6E2;
                            color: white;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 1.5rem;
                        }
                    }
                }

                .buttons{
                    display: flex;
                    justify-content: center;
                    gap: 10px;
                    span{
                        background: #F2F5F8;
                        box-shadow: 0px 1px 3px #00000033;
                        text-align: center;
                        padding: 10px 50px;
                        color: var(--primary-color);
                        user-select: none;
                        cursor: pointer;
                        border-radius: 30px;
                        transition: 0.2s;

                        &:hover {
                            background: #dee0e2;
                        }
                    }
                }
            }
        }
    }


@keyframes slide-down {
  0% {
    opacity: 0;
    height: 0px;
    overflow: hidden;
  }
  50% {
      opacity: 0;
  }
  100% {
    opacity: 1;
    height: 150px;
    overflow: hidden;
  }
}
</style>