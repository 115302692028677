<template>
    <div class="gal-overlay">
        <span class="close" @click="$emit('close')">X</span>
        <div class="gal-container">
            <section class="gal-main">
                <figure :style="{backgroundImage: images[isActive].mimetype.includes(`image/`) ? `url(${trimSrc(images[isActive].url)}` : '', backgroundPosition: images[isActive].mimetype.includes(`image/`) ? `${zoomPosition.x} ${zoomPosition.y}` : ''}" :class="{zoom: zoomIn, img: images[isActive].mimetype.includes(`image/`)}" @mousemove="handleZoom">
                    <a class="download" :download="images[isActive].name" :href="images[isActive].url" :title="images[isActive].name">
                        <img :alt="images[isActive].name" src="/assets/images/download.svg">
                    </a>
                    <img v-if="images[isActive].mimetype.includes(`image/`)" :src="trimSrc(images[isActive].url)" :class="{zoom: zoomIn}" alt="" @click="zoomIn = !zoomIn">
                    <video v-else-if="images[isActive].mimetype.includes(`video/`)" :src="trimSrc(images[isActive].url)" autoplay controls/>
                </figure>
                
            </section>
            <section class="gal-slider" @click="handleScroll">
                <span class="arrow left">
                    <img src="/assets/images/left-arrow.svg" alt="close_chevron">
                </span>
                <span class="arrow right">
                    <img src="/assets/images/right-arrow.svg" alt="open_chevron">
                </span>
                <div class="items" ref="scroll" >
                    <div class="item" v-for="(img, index) in images" :key="index" :class="{active: isActive === index}" @click="isActive = index">
                        <img v-if="img.mimetype.includes(`image/`)" :src="trimSrc(img.url)" alt="image">
                        <video v-else-if="img.mimetype.includes(`video/`)" :src="trimSrc(img.url)"/>
                        <span class="unclickable"></span>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            required: true,
            type: Object,
        },
    },

    data() {
        return {
            isActive: this.data.init || 0,
            images: this.data.items,
            zoomIn: false,
            zoomPosition: {
                x: '50%',
                y: '50%'
            }
        }
    },

    mounted() {
        
        window.addEventListener('keyup', (e) => {
            if(e.keyCode === 27) this.$emit('close')
        })
    },

    methods: {
        handleScroll(e) {
            const init = (window.screen.width / 2) + (window.screen.width / 8)
            const back = (window.screen.width / 3)
            if(e.clientX > init) this.$refs.scroll.scrollLeft += 400
            if(e.clientX < back) this.$refs.scroll.scrollLeft -= 400
        },

        handleZoom(e) {
            if (!this.zoomIn) {
                return
            }

            const image = e.currentTarget;
            
            const offsetX = e.offsetX ? e.offsetX : e.touches[0].pageX
            const offsetY = e.offsetY ? e.offsetY : e.touches[0].pageY
            const x = offsetX / image.offsetWidth * 100
            const y = offsetY / image.offsetHeight * 100

            this.zoomPosition = {
                x: `${(x > 100 ? 100 : x < 0 ? 0 : x)}%`,
                y: `${(y > 100 ? 100 : y < 0 ? 0 : y)}%`
            }

        },

        trimSrc(src) {
            const trimmedSrc = src.replace(/https?:../,'')
            return `https://${trimmedSrc}`
        },
    }
}
</script>

<style lang="scss" scoped>
.gal-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.3);
    z-index: 200;
    .close {
        position: absolute;
        top: 50px;
        right: 50px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: solid 1px white;
        user-select: none;
        cursor: pointer;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 100;
    }
    .gal-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .gal-main {
            height: 70%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            position: relative;
            figure {
                max-width: 80%;
                max-height: 90%;
                height: 100%;
                background-position: 50% 50%;
                background-size: 200%;
                background-repeat: no-repeat;
                position: relative;
                overflow: hidden;
                -webkit-user-drag: none;

                &.img {
                    cursor: zoom-in;
                }

                &.zoom {
                    cursor: zoom-out;
                }

                
                .download {
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    width: 25px;
                    height: 25px;
                    background: rgba(255,255,255,0.5);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: 0.2s;

                    &:hover {
                        background: rgba(255,255,255,0.75);
                    }

                    img {
                        width: 60%;
                        height: 60%;
                        object-fit: contain;
                    }
                }

                img, video {
                    width: 100%;
                    height: 100%;
                    border-radius: 5px;
                    opacity: 1;
                    transition: opacity .5s;
                    -webkit-user-drag: none;

                    &.zoom {
                        opacity: 0;
                    }
                }
            }
        }
        .gal-slider {
            height: 20%;
            width: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            gap: 50px;
            max-width: 1200px;
            .arrow {
                position: absolute;
                width: 50px;
                height: 50px;
                cursor: pointer;
                img {
                    filter: invert(1);
                }
                &.left {
                    left: 0;
                }
                &.right {
                    right: 0;
                }
            }
            .items {
                width: 100%;
                display: flex;
                overflow: auto;
                scroll-behavior: smooth;
                gap: 50px;
                max-width: 1000px;
                &::-webkit-scrollbar {
                    height: 0px;
                }
                .item {
                    margin: auto;
                    width: 177px;
                    min-width: 177px;
                    height: 100px;
                    background: rgba(0,0,0,0.3);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    overflow: hidden;
                    user-select: none;
                    position: relative;
                    border: solid 1px transparent;

                    &.active {
                        border: solid 1px var(--primary-color);
                    }

                    img, video {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }

                    .unclickable {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                    }
                }
            }

        }
    }
}
</style>