<template>
  <div class="video" id="video-chat-col" v-if="session"></div>
  <div class="chat" :class="{replying: replyingToMe, me: user.id === chat.sentBy.userId, reply: chat.replyTo, call: chat.type === 'joinCall' || chat.type === 'endCall' || chat.type === 'rejectCall'}">
    <div
      class="image"
      v-if="chat.type !== 'joinCall' && chat.type !== 'endCall' && chat.type !== 'rejectCall'">
      <figure>
        <img v-if="chat.sentBy.name === 'Bot'" src="/assets/images/bot_icon.png" alt="Bot Icon" />
        <img v-else-if="chat.sentBy.avatar" :src="imgURL + chat.sentBy.avatar" alt="User Icon" />
        <span v-else class="no-img" :class="{me: user.id === chat.sentBy.userId}">{{chat.sentBy.name.charAt(0)}}</span>
      </figure>
    </div>
    <div class="message-wrapper">
      <div class="info" v-if="user.id !== chat.sentBy.userId && chat.type !== 'joinCall' && chat.type !== 'endCall' && chat.type !== 'rejectCall'">
        {{ chat.sentBy.name }}
      </div>
      <div class="message">
        <types :replying-to-me="replyingToMe" :chat="chat" :isMine="user.id === chat.sentBy.userId" @join="handleRejoin" @remove="removeChat" @reply="replyToChat" @edited="handleEditedMessage" />
      </div>
      <div class="info" v-if="chat.type !== 'joinCall' && chat.type !== 'endCall' && chat.type !== 'rejectCall'">
        <span class="edited" v-if="isEdited" :title="`last edited: ${timeSince(isEdited)}`">
            <figure>
                <img src="/assets/images/pencil.svg" alt="edited">
            </figure>
            <span>edited</span>
            <span class="dot" />
        </span>
        <span>{{ date(chat.createdAt) }}</span>
        <figure v-if="user.id === chat.sentBy.userId" @click="seeReadUsers = !seeReadUsers" title="See read by" class="read-status" :class="{unread: !readByAll}">
            <img src="/assets/images/seen.svg" alt="seen_status">
        </figure>
      </div>
    </div>
  </div>
  <transition name="vertical-slide">
    <ReadBy v-if="seeReadUsers" :data="chat.receivers" @close="seeReadUsers = false" />
  </transition>
</template>

<script>
import { getCurrentInstance } from "vue";
import Types from "./Type.vue";
import ReadBy from "./ReadBy.vue";
import timeSince from '@/mixins/timeSince'
export default {
  props: {
    chat: {
      required: false,
      type: Object,
    },
    replyingToMe: {
        type: Boolean,
        default: () => false
    }
  },

  mixins: [timeSince],

  data() {
    return {
      internalInstance: getCurrentInstance(),
      imgURL: process.env.VUE_APP_DO_SPACES,
      user: localStorage.getItem("U_P")
        ? JSON.parse(localStorage.getItem("U_P"))
        : {},
      session: false,
      isEdited: undefined,
      seeReadUsers: false,
    };
  },

  computed: {
    readByAll() {
        return this.chat.receivers.map(el => el.isRead).every(el => el)
    }
  },

  emits: ['joinCall', 'removeChat', 'replyToChat'],

  components: {
    Types,
    ReadBy
  },

  created() {
    if (this.chat.isEdited) this.isEdited = this.chat.updatedAt
  },

  methods: {
      handleRejoin() {
          this.$emit('joinCall')
      },

      removeChat() {
        this.$emit('removeChat', this.chat._id)
      },

      replyToChat() {
        this.$emit('replyToChat', this.chat._id)
      },

      handleEditedMessage(data) {
        this.isEdited = data;
      },

      date(data) {
        if (
        this.internalInstance.appContext.config.globalProperties
          .$moment(data)
          .format("MMMM D, YYYY") ===
        this.internalInstance.appContext.config.globalProperties
          .$moment(Date.now())
          .format("MMMM D, YYYY")
      ) {
        return this.internalInstance.appContext.config.globalProperties
          .$moment(data)
          .format("h:mm a");
      }
      return this.internalInstance.appContext.config.globalProperties
        .$moment(data)
        .format("MMMM D, YYYY h:mm a");
      }
  }
};
</script>

<style lang="scss">
    
.vertical-slide-enter-active,
.vertical-slide-leave-active {
  transition: all 0.5s ease;
}

.vertical-slide-enter-from,
.vertical-slide-leave-to {
  opacity: 0;
  transform: translateY(150px);
}

</style>

<style lang="scss" scoped>
.chat {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  padding: 20px;

  .replying {
    background: rgba(181, 151, 106, 0.4);
  }

  &.reply {
    .image {
        margin-top: 50px;
    }
  }
  .image {
    width: 40px;
    height: 100%;
    display: flex;
    align-items: flex-start;
    padding: 0 0 5px 0;
    figure {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      user-select: none;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .no-img {
        width: 100%;
        height: 100%;
        border: solid 1px transparent;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: 600;
        background: var(--primary-color);

        &.me { 
          border: solid 1px var(--primary-color);
          color: var(--primary-color);
          background: white;
        }
      }
    }
  }
  .message-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: calc(100% - 40px);
    .info {
      font-weight: 500;
      font-size: 0.7rem;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      .edited {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        opacity: 0.4;
        font-weight: 400;

        figure {
            width: 12px;
            height: 12px;
            img {
                width: 100%;
            }
        }

        span {
            margin-right: 4px;
        }

        .dot {
            width: 2px;
            height: 2px;
            border-radius: 50%;
            background: black;
            margin-left: 4px;
        }
      }
    }
    .message {
      width: 100%;
      display: flex;
      justify-content: flex-start;
    }
  }

  &.me {
    flex-direction: row-reverse;

    &.reply {
        .image {
            margin-top: 34px;
        }
    }
    .message-wrapper {
      align-items: flex-end;

      .info {
        flex-direction: row;
        .edited {
            flex-direction: row;

            span {
                margin-right: unset;
                margin-left: 4px;
            }

            .dot {
                margin-right: 4px;
            }
        }

        .read-status {
            margin-left: 4px;
            width: 12px;
            height: 12px;
            cursor: pointer;

            img {
                width: 100%;
                -webkit-user-drag: none;
                transition: 0.2s;
            }

            &.unread {
                img {
                    filter: grayscale(1);
                }
            }
        }
      }
      .message {
        justify-content: flex-end;
      }
    }
  }

  &.call {
    width: 100%;
  }
}
</style>