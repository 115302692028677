<template>
    <div class="chat-bar" @drop="drop">
        <div class="replying" v-if="replyingTo && Object.keys(replyingTo)">
            <span class="ttl">Replying to:</span>
            <span class="msg">{{ replyingTo.text }}</span>
            <span class="cancel" @click="$emit('cancelReply')" />
        </div>
        <div class="more" @click="more = !more">
            <label>
                <input ref="import" type="file" accept="*" @change="importFiles">
                <img src="/assets/images/plus-icon.png" alt="import image">
            </label>
        </div>
        <div class="contain">
            <div class="backdrop" ref="msg">
                <div class="highlights" v-html="msgHTML" />
            </div>
            <textarea ref="bar" @input="handleMessage" @scroll="handleScroll" class="bar"
                :class="{ disabled: !timerStatus && needTimer }"
                :title="!timerStatus && needTimer ? 'Start a Timer on top right corner to start chatting' : 'Write your message here'"
                placeholder="Write a text..." @paste="handlePaste" @keydown.backspace="handleBackspace"
                @keydown.enter="keyCombo" v-model="msg" :disabled="!timerStatus && needTimer"></textarea>
        </div>
        <figure class="send" @click="sendMessage">
            <img src="/assets/images/send_icon.svg" alt="send icon">
        </figure>
        <div class="attachments" :class="{ show: more }">
            <div v-if="attachments.length" class="images">
                <div class="file" v-for="(img, index) in images" :key="index">
                    <img :src="img.link" alt="">
                    <div class="delete" @click="removeImage(index)">X</div>
                </div>
            </div>
        </div>
        <TagUser v-if="tagUser" :search-key="toSearch" :users="users" @tagUser="handleTag" />
    </div>
    <attachment v-if="attachments.length" :items="attachments" @send="sendFiles" />
</template>

<script>
import { mapActions } from "vuex";
import { UPLOAD_CHAT_ATTACHMENT } from "@/store/storeconstants";
import Attachment from './AttachmentPop.vue'
import TagUser from './TagUser.vue'
import nodeInstance from '@/services/NodeServiceinstance';
export default {
    props: {
        roomId: {
            required: false
        },
        fromDrop: {
            required: false,
            type: Array
        },
        messagesSize: {
            required: false
        },
        replyingTo: {
            type: Object,
            default: () => {}
        },
        timerStatus: {
            type: Number,
            default: () => 0
        },
        needTimer: {
            type: Boolean,
            default: () => false
        }
    },

    components:{
      Attachment,
      TagUser
    },

    data() {
        return {
            msg: '',
            more: false,
            attachments: this.fromDrop || [],
            tagUser: false,
            user: localStorage['U_P'] ? JSON.parse(localStorage['U_P']) : {},
            toSearch: '',
            users: [],
            msgHTML: '',
            toHighlight: [],
            taggedUsers: []
        }
    },
    
    emits: ['sentMessage', 'cancelReply'],

    created() {
        this.fetchChatUsers()
    },

    methods: {
        ...mapActions("chat", {
            updateChatFile: UPLOAD_CHAT_ATTACHMENT,
        }),

        async fetchChatUsers() {
            const response = await nodeInstance.get(`/chats/roomDetails/${this.roomId}`)
            if (response.status === 200) this.users = response.data.users
        },

        keyCombo(e) {
            if(!e.shiftKey) {
                e.preventDefault()
                this.sendMessage()
                return
            }

            setTimeout(() => {
                this.$refs.bar.scrollTop = this.$refs.bar.scrollHeight
                this.msgHTML += `<br />`
            }, 1);
        },

        sendMessage() {
            if (this.tagUser) return

            if (this.msg.trim() === '') return

            const msg = this.msg.replace(/\n$/, '');
            if(this.msg) {
                const params = {
                    roomId: this.roomId,
                    text: msg,
                    parentId: null,
                };

                if (this.replyingTo && Object.keys(this.replyingTo)) {
                    params.replyTo = this.replyingTo._id
                }

                if (this.toHighlight.length) {
                    params.mentionedUsers = this.toHighlight,
                    params.notifyUsers= this.toHighlight.map(el => el.id).join(',')
                }
                
                this.emitter.emit("send-message", params);
                this.$emit('sentMessage')
            }
            
            this.msg = ''
            this.msgHTML = ''
            this.$emit('cancelReply')
            
        },

        handlePaste(data) {
            const items = data.clipboardData.items
            
            if (items[0].kind === 'file') {
                const file = {target:{
                    files: [items[0].getAsFile()]
                }}
                this.importFiles(file)
            }
        },

        async importFiles(event) {
            const file = event.target.files[0]

            if (file.type.match(/image.*/)) {
                const fr = new FileReader();
                fr.readAsDataURL(file);
                fr.onload = () => {
                    this.attachments.push({
                        original: file,
                        link: fr.result
                    })
                }
            }
            else (
                this.attachments.push({
                    original: file,
                })
            )
        },
        drop(e){
            e.stopPropagation();
            e.preventDefault();
            const files = e.dataTransfer.files

            files.forEach(file => {
                if (file.type.match(/image.*/)) {
                    const fr = new FileReader();
                    fr.readAsDataURL(file);
                    fr.onload = () => {
                        this.attachments.push({
                            original: file,
                            link: fr.result
                        })
                    }
                }
                else (
                    this.attachments.push({
                        original: file,
                    })
                )
            });
        },

        async sendFiles(data){
            let params = {
                    attachment: data.files,
                    text: data.message || '',
                    room: this.roomId,
                };

            this.attachments = []

            await this.updateChatFile(params)
        },

        handleTag(user) {
            this.msg = this.msg.replace(`@${this.toSearch}`, `@${user.name}`)

            const word = `@${user.name}`

            const tagObject = {
                id: user.id,
                name: user.name,
                start: this.msg.indexOf(word),
                end: this.msg.indexOf(word) + word.length,
                _id: this.roomId
            }
            
            this.toHighlight.push(tagObject)

            setTimeout(() => {
                this.atUser()
                this.highlightMessages()
            }, 200);
        },

        handleMessage(e) {
            if(e.data === '@') this.atUser()

            if (!this.msg.includes('@')) this.tagUser = false

            if (e.data === ' ' && this.tagUser) this.atUser()

            if (this.msg.includes('@@')) {
                this.msg = this.msg.replace('@@', '')
            }
            
            this.toSearch = this.msg.split('@').pop()

            this.highlightMessages()

            const params = {
                roomId: this.roomId,
            }
            this.emitter.emit("is-typing", params);
        },

        highlightMessages() {
            let msg = this.$refs.bar.value

            this.toHighlight.forEach(el => {
                msg = msg.replace(`@${el.name}`, `<span class="highlight">@${el.name}</span>`)
            })

            this.msgHTML = msg
        },

        handleScroll(e){
            this.$refs.msg.scrollTop = e.target.scrollTop
        },

        handleBackspace() {
            if(this.msgHTML.substr(this.msgHTML.length - 6) === '<br />') {
                this.msgHTML = this.msgHTML.replace(/.{0,6}$/, '')
            }
        },

        atUser() {
            if (this.tagUser) {
                this.tagUser = false
                this.toSearch = ''
                return
            }

            this.tagUser = true
        }
    },
}
</script>

<style lang="scss" scoped>
.chat-bar {
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    position: relative;
    border-top: solid 1px #F2F5F8;
    position: relative;

    @media only screen and (max-width: 1200px) {
        width: 100%;
        padding: 10px 5px;
    }

    .replying {
        position: absolute;
        top: -16px;
        left: 60px;
        width: calc(100% - 119px);
        padding: 10px;
        background: #e6e8eb;
        border-radius: 4px 4px 0 0;
        display: flex;
        align-items: center;

        .ttl {
            font-weight: 600;
            font-size: 12px;
            color: var(--primary-color);
        }

        .msg {
            margin-left: 8px;
            opacity: 0.6;
        }

        .cancel {
            width: 20px;
            height: 20px;
            position: absolute;
            right: 10px;
            background: rgba(0, 0, 0, 0.1);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            user-select: none;
            transition: 0.2s;

            &:hover {
                background: rgba(0, 0, 0, 0.2);
            }

            &::before,
            &::after {
                content: '';
                position: absolute;
                background: $red;
                width: 2px;
                height: 12px;
                opacity: 0.8;
            }

            &::before {
                transform: rotate(45deg);
            }

            &::after {
                transform: rotate(-45deg);
            }
        }
    }

    div {
        user-select: none;
        cursor: pointer;
    }

    .more {
        display: flex;
        justify-content: center;
        align-items: center;

        label {
            width: 20px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            transition: 0.2s;

            input {
                display: none;
            }

            img {
                width: 100%;
                transition: 0.2s;
            }
        }
    }

    .contain {
        width: calc(100% - 40px);
        height: 50px;
        background: #F2F5F8;
        display: flex;
        align-items: center;


        textarea {
            padding: 10px 20px;
            border-radius: 5px;
            font-size: 0.8rem;
            background: transparent;
            width: 100%;
            height: 100%;
            z-index: 1;
            resize: none;

            &:focus {
                outline: none;
            }

            &.disabled {
                background: $grey;
                cursor: no-drop;
            }
        }

        .backdrop {
            padding: 10px 20px;
            position: absolute;
            color: transparent;
            user-select: none;
            font-size: 0.8rem;
            z-index: 0;
            width: 100%;
            height: 50px;
            overflow: auto;

            .highlights {
                white-space: pre-wrap;
                word-wrap: break-word;
                color: transparent;
            }
        }
    }

    .send {
        width: 40px;
        z-index: 2;
        cursor: pointer;

        img {
            width: 50%;
            -webkit-user-drag: none;
        }
    }

    .attachments {
        position: absolute;
        top: 0;
        transition: 0.2s;
        display: none;

        &.show {
            display: flex;
            animation: show 0.2s forwards;
        }
    }
}


@keyframes show {
    from {
        top: -15px;
        opacity: 0;
    }

    to {
        top: -45px;
        opacity: 1;
    }
}
</style>

<style lang="scss">.highlight {
    width: fit-content;
    color: transparent;
    border-radius: 3px;
    background-color: #abe5e9;
}</style>
