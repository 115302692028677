<template>
    <div class="attachments-wrapper">
        <div class="close" @click="$emit('close')">
            <img src="/assets/images/left-arrow.svg" alt="close_chevron">
        </div>
        <template v-if="loaded">
            <div class="media">
                <div class="title" @click="$emit('close')">Media</div>
                <hr />
                <div class="items">
                    <template v-if="media.length">
                        <template v-for="(item, index) in media" :key="index">
                            <figure class="item">
                                <img v-if="item.mimetype.includes(`image/`)" :src="trimSrc(item.url)" alt="" @click="handleGallery(index)" />
                                <video v-else-if="item.mimetype.includes(`video/`)" :src="trimSrc(item.url)" @click="handleGallery(index)" />
                            </figure>
                        </template>
                    </template>
                    <template v-else>
                        <span class="empty">This Chat has no Media Files</span>
                    </template>
                </div>
            </div>
            <div class="files">
                <div class="title" @click="$emit('close')">Attachments</div>
                <hr />
                <div class="docs">
                    <template v-if="files.length">
                        <template v-for="(item, index) in files" :key="index">
                            <div class="item" @click="handleDoc(item.url)">
                                <img class="doc" src="/assets/images/doc-icon.svg" alt="">
                                <div>
                                    <span>{{item.name}}</span>
                                    <span class="extra">Sent {{timeSince(item.updatedAt)}}</span>    
                                </div>
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <span class="empty">This Chat has no Files</span>
                    </template>
                </div>
            </div>
            <gallery v-if="Object.keys(toGallery).length" :data="toGallery" @close="toGallery = {}"/>
        </template>
        <template v-else>
            <div class="loading">
                <div class="loader"></div>
            </div>
        </template>
        
    </div>
    
</template>

<script>
import { mapGetters } from 'vuex';
import { GET_USER_PROFILE_GETTER } from '@/store/storeconstants';
import Gallery from './Gallery.vue'
import nodeInstance from '@/services/NodeServiceinstance';
import timeSince from '@/mixins/timeSince'
export default {
    props: {
        room: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            files: [],
            currentPage: 1,
            toGallery: {},
            media: [],
            loaded: false
        }
    },

    mixins: [timeSince],

    emits: ['close'],

    components: {
        Gallery
    },

    computed: {
        ...mapGetters('account', {
          getUserProfile: GET_USER_PROFILE_GETTER,
        }),
    },

    watch: {
        room() {
            this.loaded = false
            this.getAttachments()
        }
    },

    methods: {
        async getAttachments() {
            this.loaded = false
            const response = await nodeInstance.get(`chats/attachments?room=${this.room}&page=${this.currentPage}&limit=50`)
            this.loaded = true
			if(response.status === 200) {
                const media = response.data.attachments.filter(el => el.mimetype.includes(`image/`) ||  el.mimetype.includes(`video/`))
                const files = response.data.attachments.filter(el => !el.mimetype.includes(`image/`) &&  !el.mimetype.includes(`video/`))
                
                if (this.currentPage !== 1) {
                    this.files.push(...files)
                    this.media.push(...media)
                    return
                }
                
                this.files = files
                this.media = media
            }
        },

        handleGallery(sel) {
            this.toGallery = {
                items: this.media,
                init: sel
            }
        },

        handleDoc(data) {
            window.open(data)
        },

        trimSrc(src) {
            const trimmedSrc = src.replace(/https?:../,'')
            return `https://${trimmedSrc}`
        },
    },

    mounted() {
        this.getAttachments()
    }
}
</script>

<style lang="scss" scoped>
.attachments-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    border-left: solid 1px rgba(0,0,0,0.1);
    user-select: none;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    .close {
        width: 10px;
        height: 10px;
        transform: rotate(270deg);
        cursor: pointer;
    }

    .media {
        display: flex;
        flex-direction: column;
        height: 70%;
        width: 100%;
        gap: 1rem;

        .items {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            gap: 4px;
            .item {
                width: 32.3%;
                max-height: 80px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                background: white;
                img, video {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }

                .doc {
                    width: 100%;
                    object-fit: contain;
                }

                &:hover {
                    background: rgba(0,0,0,0.1);
                }
            }

            .empty {
                width: 100%;
                height: 100%;
            }
        }
    }

    hr {
        border: none;
        width: 100%;
        height: 1px;
        background: rgba(0,0,0,0.1);
    }

    .files {
        display: flex;
        flex-direction: column;
        height: 30%;
        width: 100%;
        gap: 1rem;

        .docs {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            align-items: flex-start;
            width: 100%;
            overflow: auto;
            height: 30%;

            .item {
                width: 100%;
                display: flex;
                align-items: center;
                gap: 1rem;
                user-select: none;
                cursor: pointer;

                div {
                    display: flex;
                    flex-direction: column;

                    span {
                        color: var(--primary-color);
                    }

                    .extra {
                        color: $grey;
                        font-size: 0.7rem;
                    }
                }
            }
        }
    }
    

    
}
</style>