<template>
    <div class="attachments-wrapper">
        <div class="close" @click="$emit('close')">
            <img src="/assets/images/left-arrow.svg" alt="close_chevron">
        </div>
        <div class="title" @click="$emit('close')">Members</div>
        <hr />
        <div class="items">
            <template v-if="users.length && loaded">
                <template v-for="(item, index) in users" :key="index">
                    <div class="item">
                        <figure>
                            <img v-if="item.userInfo.avatar" :src="imgURL + item.userInfo.avatar" alt="avatar">
                            <span v-else>{{item.userInfo.name.charAt(0)}}</span>
                        </figure>
                        <span>{{item.userInfo.name}}</span>
                        <span v-if="canCreate.includes(getUserProfile.role?.id)" class="remove" @click="removeUserFromRoom(item.userId)" />
                    </div>
                </template>
            </template>
            <template v-else-if="!loaded">
                <div class="loading">
                    <div class="loader"></div>
                </div>
            </template>
            <template v-else>
                <span class="empty">This Chat has no users</span>
            </template>
        </div>
    </div>
    
</template>

<script>
import nodeInstance from '@/services/NodeServiceinstance';
import { mapGetters } from 'vuex'
import { GET_USER_PROFILE_GETTER } from "@/store/storeconstants";

export default {
    props: {
        room: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            users: [],
            currentPage: 1,
            org: localStorage.organization ? JSON.parse(localStorage.organization) : {},
            imgURL: process.env.VUE_APP_DO_SPACES,
            loaded: false,
            canCreate: [4, 5, 6],
        }
    },

    computed: {
        ...mapGetters("account", {
            getUserProfile: GET_USER_PROFILE_GETTER,
        }),
    },

    emits: ['close'],

    watch: {
        room() {
            this.loaded = false
            this.getUsers()
        }
    },

    methods: {
        async getUsers() {
            const response = await nodeInstance.get(`/chats/roomDetails/${this.room}`)
            this.loaded = true
			if(response.status === 200) this.users = response.data.users
        },

        async removeUserFromRoom(user) {
            const toSend = {
                userId: user,
                roomId: this.room
            }
            const response = await nodeInstance.patch(`/chats/removeUserFromRoom`, toSend)

            if(response.status === 200) {
                this.getUsers()
                this.emitter.emit("alert", "User removed from room with success");
            }
        }
    },

    mounted() {
        this.getUsers()
    }
}
</script>

<style lang="scss" scoped>
.attachments-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    border-left: solid 1px rgba(0,0,0,0.1);
    user-select: none;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    .close {
        width: 10px;
        height: 10px;
        transform: rotate(270deg);
        cursor: pointer;
    }
    hr {
        border: none;
        width: 100%;
        height: 1px;
        background: rgba(0,0,0,0.1);
    }
    .items {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        gap: .5rem;
        overflow: auto;
        .item {
            width: 100%;
            max-height: 80px;
            display: flex;
            align-items: center;
            background: white;
            gap: 1rem;
            border-bottom: solid 1px rgba(0,0,0,0.1);
            padding: 0 1rem .5rem;
            position: relative;

            figure {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                span {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: var(--primary-color);
                    color: white;
                    font-weight: 500;
                }
            }

            span {
                width: calc(100% - 30px - 1rem);
                color: var(--primary-color);
            }

            .remove {
                position: absolute;
                width: 24px;
                height: 24px;
                right: .5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                &::before, &::after {
                    width: 12px;
                    height: 1px;
                    content: '';
                    position: absolute;
                    background: $red;
                }

                &::before {
                    transform: rotate(45deg);
                }
                &::after {
                    transform: rotate(-45deg);
                }
            }
            
        }

        .empty {
            width: 100%;
            height: 100%;
        }
    }
}
</style>