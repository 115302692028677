<template>
    <section>
        <div class="wrapper">
            <div class="items">
                <div class="item" v-for="(file, index) in filteredFiles" :key="index" :title="file.original.name">
                    <div class="img" v-if="file.link">
                        <img :src="file.link" alt="">
                    </div>
                    <div class="file" v-else>
                        <img src="/assets/images/doc-icon.svg" alt="">
                    </div>
                    <div class="info">
                        {{file.original.name}}
                    </div>
                    <div class="delete-item" @click="del(index)">
                        X
                    </div>
                </div>
                <div class="add" v-if="filteredFiles.length < 10">
                    <label>
                        <input ref="import" type="file" accept="*" @change="importFiles">
                        +
                    </label>
                </div>
            </div>
            <div class="text-box">
                <textarea ref="comment" placeholder="Add a comment (optional)"/>
                <img @click="sendFiles" src="/assets/images/send_blue.svg" alt="">
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        items: {
            required: false,
            type: Array,
        },
    },

    data() {
        return {
            filteredFiles: this.items
        }
    },

    methods: {
        importFiles(event) {
            const file = event.target.files[0]

            if (file.type.match(/image.*/)) {
                const fr = new FileReader();
                fr.readAsDataURL(file);
                fr.onload = () => {
                    this.filteredFiles.push({
                        original: file,
                        link: fr.result
                    })
                }
            }
            else (
                this.filteredFiles.push({
                    original: file,
                })
            )
        },

        del(index) {
            this.filteredFiles.splice(index, 1)
        },

        sendFiles() {
            const toSend = {
                files: this.filteredFiles,
                message: this.$refs.comment.value
            }
            this.$emit('send', toSend)
        }
    },
}
</script>

<style lang="scss" scoped>
    section {
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.1);
        backdrop-filter: blur(2px);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;

        .wrapper {
            width: 90%;
            max-width: 1000px;
            max-height: 400px;
            background: rgba(0,0,0,0.3);
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 20px;
            .items {
                display: flex;
                gap: 10px;
                width: 100%;
                flex-wrap: wrap;
                .item {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    gap: 5px;
                    background: rgba(0,0,0,0.1);
                    border-radius: 5px;
                    padding: 10px;
                    cursor: pointer;
                    transition: 0.2s;
                    position: relative;

                    &:hover {
                        background: rgba(0,0,0,0.3);
                    }
                    .file {
                        img {
                            filter: invert(1);
                            object-fit: contain !important;
                        }
                    }
                    .img,
                    .file  {
                        width: 100px;
                        height: 100px;
                        border-radius: 5px;
                        overflow: hidden;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: scale-down;
                        }
                    }
                    .info {
                        color: white;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        width: 100px;
                        height: 1.2em;
                        white-space: nowrap;
                    }

                    .delete-item {
                        position: absolute;
                        top: -10px;
                        right: -10px;
                        width: 20px;
                        height: 20px;
                        background: #ED0423;
                        border-radius: 50%;
                        color: white;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        user-select: none;
                        font-weight: bold;
                        transition: 0.2s;

                        &:hover {
                            background: #b4081f;
                        }
                    }
                }

                .add {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 120px;
                    border-radius: 5px;
                    overflow: hidden;
                    background: var(--primary-color);
                    user-select: none;

                    &:hover {
                        background: #0072FFbe;
                    }

                    label {
                        width: 100%;
                        height: 100%;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 0;
                        color: white;
                        font-size: 2.5rem;
                        transition: 0.2s;

                        input {
                            display: none;
                        }
                    }
                }
            }

            .text-box {
                width: 100%;
                background: white;
                border-radius: 5px;
                overflow: hidden;
                position: relative;
                display: flex;
                align-items: center;
                textarea {
                    width: 100%;
                    height: 70px !important;
                    resize: none;
                    padding: 10px 70px 10px 10px;

                    &:focus {
                        outline: none;
                    }
                }

                img {
                    position: absolute;
                    right: 20px;
                    width: 30px;
                    height: 30px;
                    cursor: pointer;
                }

            }
        }
    }
</style>