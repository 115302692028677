<template>
    <div class="read-users">
        <header>
            <h2>Receivers:</h2>
            <span @click="$emit('close')" />
        </header>
        <hr>
        <section class="users">
            <template v-for="(user, i) in data" :key="i">
                <article>
                    <figure>
                        <img v-if="user.avatar" :src="imgURL + user.avatar" alt="avatar">
                        <span v-else>{{ user.name.charAt(0) }}</span>
                    </figure>
                    <span class="name">
                        {{ user.name }}
                    </span>
                    <figure class="status" :class="{unread: !user.isRead}">
                        <img src="/assets/images/seen.svg" alt="seen_status">
                    </figure>
                    <span class="read-at" v-if="user.isRead">
                        Read at {{ readAt(user.isReadAt) }}
                    </span>
                    <span class="read-at" v-else>
                        Not Read
                    </span>
                </article>
            </template>
        </section>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            imgURL: process.env.VUE_APP_DO_SPACES,
        }
    },

    methods: {
        readAt(date) {
            return `${new Date(date).toLocaleTimeString()} - ${new Date(date).toLocaleDateString()}`
        }
    }
} 
</script>

<style lang="scss" scoped>
    .read-users {
        width: 100%;
        background: rgb(248, 248, 248);
        position: absolute;
        bottom: 0;
        height: 300px;
        z-index: 20;
        left: 0;
        border-radius: 8px 8px 0 0;
        padding: 20px;

        header {
            display: flex;
            justify-content: space-between;
            height: 20px;

            h2 {
                font-weight: 600;
                font-size: 16px;
                color: var(--primary-color);
                height: 20px;
                margin-bottom: 0;
            }
            
            span {
                width: 20px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                &::before, &::after {
                    width: 12px;
                    height: 2px;
                    content: '';
                    position: absolute;
                    background: black;
                }

                &::before {
                    transform: rotate(45deg);
                }
                &::after {
                    transform: rotate(-45deg);
                }
            }

            
        }

        hr {
            margin: 12px 0;
            width: 100%;
            height: 1px;
            background: rgb(202, 202, 202);
        }

        .users {
            height: calc(100% - 46px);
            display: flex;
            flex-direction: column;
            overflow: auto;

            article {
                display: flex;
                align-items: center;
                margin-top: 12px;

                &:first-of-type {
                    margin-top: 0;
                }

                figure {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        -webkit-user-drag: none;
                    }

                    span {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: var(--primary-color);
                        color: white;
                        font-weight: 500;
                    }
                }
                
                .name {
                    margin-left: 8px;
                    font-weight: 500;
                }

                .status {
                    margin-left: 12px;
                    width: 16px;
                    height: 16px;

                    img {
                        width: 100%;
                        transition: 0.2s;
                    }

                    &.unread {
                        img {
                            filter: grayscale(1);
                        }
                    }
                }

                .read-at {
                    margin-left: 12px;
                    font-weight: 400;
                    font-size: 12px;
                }
            }
        }
    }
</style>